import BasePlugin from '../BasePlugin'

export default class FillScenarioFromSource extends BasePlugin {
  async execute () {
    let scenarioId = this.context.getParentContext().getModel().id
    let mode = this.context.getDashboardComponents()['component_3e0bc4c2-656c-4ed7-9feb-020dad95beec'][0].$data.localModel
    let newRaw = this.context.getDashboardComponents()['component_1d88715d-812b-401f-a8f9-b36bfebe6dc8'][0].$data.localModel
    let carryoverRaw = this.context.getDashboardComponents()['component_85bc0873-6a05-4877-ab86-06c44ad86ad8'][0].$data.localModel
    let selectedScenario = this.context.getDashboardComponents()['component_b4d626d9-03f4-4c9d-8de8-07fcf4a649bd'][0].localModel ?? null

    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/FillScenarioFromSourceAsyncCommand`,
      {
        'current_scenario_id': scenarioId,
        'mode': mode,
        'new_raw': newRaw,
        'carryover_raw': carryoverRaw,
        'selected_scenario': selectedScenario,
        'async': 'true'
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })

    this.context.$msgbox.close()
    this.context.$message({
      type: 'info',
      center: true,
      message: 'Заполнение сценария поставлено в очередь. По завершении Вы получите уведомление.'
    })
  }
}
